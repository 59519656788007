@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://cdn.rawgit.com/innks/NanumSquareRound/master/nanumsquareround.min.css');
@import url('https://fonts.googleapis.com/css?family=Orbitron');

body {
  font-size: 14px;
  font-family: 'Montserrat', 'NanumSquareRound', sans-serif;
}

input[type="password"] {
  font-family: Verdana, 'Montserrat', 'NanumSquareRound', sans-serif;
}